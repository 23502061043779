.active{
  background-color:#196f80;
  border-color: #196f80;
	color: #fff;
  border-radius: 5px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}


.activeMenu{
  composes: active;
}

.link{
  height: 45px;
  width: 150px;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
 cursor: pointer;
}
.link:hover{
  background-color:#196f80;
  border-color: #196f80;
  color: #ffffff;
  border-radius: 5px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
