.root{
  display: flex;

}
.list{
  width: 70%;
  overflow-y: scroll;
}

.title{
  text-align: center;
  font-size:35px;
}
.container{
  display: flex;
  width: 100%;
  justify-content: center;
}
.searchBarContainer{
  display: flex;
  justify-content: space-between;
}
