.root{
  width: 100%;
  text-align: left;
  border-bottom: 1.5px solid #d0d0d0;
  margin-bottom: 10px;
}

.title{
  font-size: 35px;
  font-family:inherit;
  font-weight: 700;
  color: #292929;
}
