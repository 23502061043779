@import '../Components/Agenda/build/styles.css';
@import '~react-datetime/css/react-datetime.css';
@import '~video-react/styles/scss/video-react'
.video-free{
  margin-top: 31px;
}

.fotter-freeclas {
  position: -webkit-sticky;
  position: sticky;
  /*1rem*/
  bottom: .1px;
  align-self: flex-end;
  padding: 0px;
}
.sticky-container-left{
  z-index: 1100;
}
.dropdown-menu{
height: 200px!important;
overflow: auto!important;
}
.center-img{
    width: 330px;
  display:block;
  margin:auto;
  text-align: center;
  margin-top: 10px;
}
