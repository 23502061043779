.searchBarContainer{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}
.input{
  border-radius: 7px;
  width: 20%;
}

.button{
  border-radius: 7px;
  width: 120px;
  height: 40px;
}
.icon{
  width: 30px;
}
