.root{
  background-color: #fff;
  min-height: 50rem;

}
.menuContainer{
    display: flex;
    justify-content: center;
    gap: 3rem;

}
.menuLink{
  font-size: 20px;
  width: 250px;
  text-align: center;
  height: 35px;
}


.menuLink:hover{
  background-color: #ffa500;
  color:#fff;

}

.menuLinkActive{
  background-color: #ffa500;
  color: #fff;
  font-size: 20px;
  border-radius: 6px;
}
