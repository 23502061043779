.root{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}
.whiteSpace{
  height: 70px;
background-color: #fff;
width: 150%;
margin-left: -28px;
margin-top: 48px;
border-radius: 10px;
}
.containerCheckbox{
  display: grid;
}
.btnContainer{
  display: flex;
  align-items: center;
  justify-content:space-around;
  width: 100%;
  margin-top: 20px;
}
