@media(max-width:980px){
  .root{
    display: grid;
    width: 100%;
  }
  .Front_divide{
    text-align: center;
    color: gray;
    background-color: #f0f0f0;
    border-radius: 10px;
    margin-top: 20px;
    min-height: 50vh;
    margin-right: 5px;
    margin-left: 5px;
    width: 100%;
  }
  .Front_divide_classes{
    text-align: center;
    color: gray;
    background-color: #f0f0f0;
    border-radius: 10px;
    width: 100%;
    margin-top: 20px;
    min-height: 50vh;
    margin-right: 5px;
    margin-left: 5px;
  }
}
@media(min-width:980px){
  .root{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .Front_divide{
    text-align: center;
    color: gray;
    background-color: #f0f0f0;
    border-radius: 10px;
    margin-top: 20px;
    min-height: 50vh;
    margin-right: 5px;
    margin-left: 5px;
    width: 80%;
  }
  .Front_divide_classes{
    text-align: center;
    color: gray;
    background-color: #f0f0f0;
    border-radius: 10px;
    width: 20%;
    margin-top: 20px;
    min-height: 50vh;
    margin-right: 5px;
    margin-left: 5px;
  }
}
.join{
  margin-top: 10px;
}
.sub{
  font-size: 13px;
}
.subText{
  color: gray;
}

.tableBody>tr>td{
  font-size: 12px;
}

.td{
  display: grid;
}
.container{
  display: flex;
}
.watchContainer{
  display: flex;
  justify-content: space-between;
}
.small{
  font-size: 12px;
  color: gray;
}
.editBtn{
  cursor: pointer;
  color: gray;
  font-size: 14px;
}
