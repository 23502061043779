.img{
  height: 600px;
}
.root{
  display: flex;
  justify-content: center;
}

@media(max-width:980px){
  .root{
    display: none;
  }
}
