
.nav-item{
	padding-right: 12px;
	padding-left: 12px;
}
.flag{
	max-height: 20px;
	max-width: 20px;
	margin-top: 10px;
}

.reserve-container{
	position: relative;
}
.card-body{
	-webkit-transition: all 1s ease; /* Safari and Chrome */
  	-moz-transition: all 1s ease; /* Firefox */
  	-o-transition: all 1s ease; /* IE 9 */
  	-ms-transition: all 1s ease; /* Opera */
  	transition: all 1s ease;
}
.card-body:hover{
	-webkit-transform: scale(1.05);
	-moz-transform: scale(1.05);
	-o-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
  color: #f0f0f0!important;
}

.reserve-text{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.image-reserve{
	opacity: 0.4;
	max-height: 750px;
}

.carousel-caption{
	top: 40%;
}

.img-slider{
	min-height: 320px;
}

.navMenu{
	padding-top: 75px;
	padding-bottom: 20px;
}

.card-img-top{
	height: 200px;
}

.map{
	height: 320px;
}

@media screen and (max-width : 1920px){
  .only-mobile{
  visibility:hidden;
  }
}

@media screen and (max-width : 506px){

 .only-mobile{
  visibility:visible;
  }
}

.footer{
	margin-bottom: 30px;
	margin-top: 30px;
	padding-top: 20px;
}
.social-icon{
	font-size:28;
}

footer{
	position: fixed;
	bottom: 0px;
	padding-top: 20px;
}
