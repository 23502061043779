.buttonContainer{
  display: grid;
  text-align: center;
}

.button{
  margin-top: 10px;
}
.link{
  border: 1px solid #d0d0d0;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  display: block;
  text-align: center
}
