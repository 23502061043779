.root{
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 10rem;
  min-width: 300px;
}
.logo{
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
}
