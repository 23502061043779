.fullModal{
  width: 100%!important;
}
.fullModal>div{
  max-width: 100%!important;
}
.table{
min-height: 400px;
width: 100%;
max-height: 600px;
overflow-y: scroll;
margin-bottom: 100px;
}
.searchBar{
  display:flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.searchBar>input{
  border-color: #d0d0d0;
}
.searchBar>input:focus {
  outline: none;
}
