.join{
  margin-top: 10px;
}
.sub{
  font-size: 13px;
}
.subText{
  color: gray;
}
.Front_divide{
  text-align: center;
  color: gray;
  background-color: #f0f0f0;
  border-radius: 10px;
  margin-top: 20px;
  min-height: 50vh;
  margin-right: 10px;
  margin-left: 5px;
}
.tableBody>tr>td{
  font-size: 12px;
}

.td{
  display: grid;
}
.container{
  display: flex;
}
