.root{
border: 1px solid #d0d0d0;
margin-top: 30px;
margin-bottom: 30px;
border-radius: 5px;
}

.child{
  display: block;
  text-align: center;

}
/*className="justify-content-md-center"*/
